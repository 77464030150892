<template>
  <div class="ins-pass-table">
      <a-table
        rowKey="id"
        :data-source="passengersData"
        :columns="columns"
        :pagination="false"
        bordered
      >
        <span slot="index" slot-scope="text, record, index">
        {{ index + 1 }}
        </span>
        <span slot="passengerType" slot-scope="text">
          {{ text | passengerTypeFilter }}
        </span>
        <span slot="certType" slot-scope="text">
          {{ text | certTypeFilter }}
        </span>
        <span slot="ticketNo" slot-scope="text, record">
          {{ record.pnrInfo.ticketNo }}
        </span>
        <div slot="prod" slot-scope="text, record">
          <div class="prod-selector">
            <span>产品: </span>
            <a-select size="small" :value="record.prodSelect.type" style="width: 60px;" @change="onProdTypeChange(record, $event)">
              <a-select-option v-for="item in prodTypeOptions" :key="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
            <a-select  size="small" :value="record.prodSelect.offerItemId" style="width: 150px; marginLeft: 8px" @change="onProdChange(record, $event)">
              <a-select-option v-for="item in prodOptions" :key="item.value">
                <span :class="item.value === 0 && 'empty-prod-option'">{{ item.label }}</span>
              </a-select-option>
            </a-select>
          </div>
        </div>
      </a-table>
  </div>
</template>

<script>
import { passengerTypeFilter, certTypeFilter } from '@/filters/passenger'
import moment from 'moment'

import _ from 'lodash'

const columns = [
  {
    title: '序号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'index' }
  },
  {
    title: '旅客姓名',
    dataIndex: 'passengerName'
  },
  {
    title: '旅客类型',
    dataIndex: 'passengerType',
    scopedSlots: { customRender: 'passengerType' }
  },
  {
    title: '证件类型',
    dataIndex: 'certType',
    scopedSlots: { customRender: 'certType' }
  },
  {
    title: '证件号码',
    dataIndex: 'certNo'
  },
  {
    title: '票号',
    dataIndex: 'ticketNo',
    scopedSlots: { customRender: 'ticketNo' }
  },
  {
    title: '手机号码',
    dataIndex: 'phone'
  },
  {
    title: '航班号',
    dataIndex: 'flightNo'
  },
  {
    title: '航班日期',
    dataIndex: 'flightDate'
  },
  {
    title: '起飞时间',
    dataIndex: 'startFlightDate'
  },
  {
    title: '产品选择',
    dataIndex: 'prod',
    scopedSlots: { customRender: 'prod' }
  },
  {
    title: '价格小计',
    dataIndex: 'prodAmount'
  }
]

const prodTypeOptions = []

const defaultProds = [
  {
    value: 0,
    label: '不购买'
  }
]

const prodTypeLabelMap = {
  INSURANCE: '保险'
}

export default {
  name: 'InsurancePassengerTable',
  data () {
    return {
      columns,
      passengersData: [],
      prodTypeOptions,
      prodOptions: defaultProds
    }
  },
  props: {
    trip: Object
  },
  mounted () {
    // this.init()
  },
  methods: {
    init () {
      const { segment, segmentName, passengers, prods } = this.trip
      let passengersCopy = _.cloneDeep(passengers)
      const segmentCopy = _.cloneDeep(segment)

      this.parseProds(prods)

      passengersCopy = passengersCopy.map(passenger => {
        return {
          ...passenger,
          segmentName,
          segment: segmentCopy,
          flightNo: segment.flightNo,
          flightDate: moment(segment.flightDate).format('YYYY-MM-DD'),
          startFlightDate: moment(segment.depTime).format('YYYY-MM-DD HH:mm'),
          prodSelect: {
            type: this.prodTypeOptions[0].value,
            offerItemId: this.prodOptions[0].value,
            rawProd: {}
          },
          prodAmount: 0
        }
      })

      this.passengersData = passengersCopy
    },
    parseProds (prods) {
      const prodTypeOptions = prods.map((prod) => {
        return {
          value: prod.serviceDTO.serviceType,
          label: prodTypeLabelMap[prod.serviceDTO.serviceType]
        }
      })

      this.prodTypeOptions = prodTypeOptions

      const prodOptions = prods.map((prod) => {
        return {
          value: prod.offerItemID,
          label: prod.prodDetail.productName,
          netFare: prod.netFare,
          rawProd: prod
        }
      })

      this.prodOptions = defaultProds.concat(prodOptions)
    },
    onProdTypeChange (passenger, prodType) {
      passenger.prodSelect.type = prodType
    },
    onProdChange (passenger, prodValue) {
      this.prodChange(passenger, prodValue)
    },
    prodChange (passenger, prodValue) {
      passenger.prodSelect.offerItemId = prodValue
      const selectProd = this.prodOptions.find((prod) => {
        return prod.value === prodValue
      })

      if (selectProd.value === 0) {
        passenger.prodAmount = 0
      } else {
        passenger.prodAmount = selectProd.netFare.amount
      }

      passenger.prodSelect.rawProd = selectProd.rawProd

      this.$emit('change', this.trip, passenger, selectProd, this.passengersData)
    },
    deletePassengerProd (deleteTicketNo, deleteSegment) {
      const targetPassenger = this.passengersData.find((passenger) => {
        const { pnrInfo, segment } = passenger
        const { ticketNo } = pnrInfo
        return ticketNo === deleteTicketNo && deleteSegment.depCode === segment.depCode
      })
      this.prodChange(targetPassenger, 0)
    },
    onAddProd () {

    }
  },
  filters: {
    passengerTypeFilter,
    certTypeFilter
  }
}
</script>

<style lang="scss" scoped>
.empty-prod-option {
  color: #aaa;
}

.ins-pass-table {
  ::v-deep {
    .ant-table tr td {
      white-space: nowrap;
    }
  }
}

</style>
