import { certTypeMap, passengerTypeMap } from '@/dicts/passenger'
import { mapFilter } from './util'

export function certTypeFilter(value) {
    return mapFilter(value, certTypeMap)
}

export function passengerTypeFilter(value) {
    return mapFilter(value, passengerTypeMap)
}
