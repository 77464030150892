import { render, staticRenderFns } from "./insurance-cart-table.vue?vue&type=template&id=37d9d05a&scoped=true&"
import script from "./insurance-cart-table.vue?vue&type=script&lang=js&"
export * from "./insurance-cart-table.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d9d05a",
  null
  
)

export default component.exports