<template>
  <div class="ins-cart-table">
    <a-table
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      :data-source="list"
      :columns="columns"
      :pagination="false"
      bordered
    >
      <span slot="index" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="prodType" slot-scope="text, record">
        {{ record.prodSelect.type |serviceTypeFilter }}
      </span>
      <span slot="ticketNo" slot-scope="text, record">
        {{ record.pnrInfo.ticketNo }}
      </span>
      <span slot="prodName" slot-scope="text, record">
        {{ record.prodSelect.rawProd && record.prodSelect.rawProd.prodDetail.productName }}
      </span>
      <span slot="company" slot-scope="text, record">
        {{ record.prodSelect.rawProd && record.prodSelect.rawProd.prodDetail.companyName }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="onDelete(record)">删除</a-button>
      </span>
    </a-table>
  </div>
</template>

<script>
import { serviceTypeFilter } from '@/filters/insurance'

const columns = [
  {
    title: '序号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'index' }
  },
  {
    title: '旅客姓名',
    dataIndex: 'passengerName'
  },
  {
    title: '证件号码',
    dataIndex: 'certNo'
  },
  {
    title: '票号',
    dataIndex: 'ticketNo',
    scopedSlots: { customRender: 'ticketNo' }
  },
  {
    title: '航段',
    dataIndex: 'segmentName'
  },
  {
    title: '产品类型',
    dataIndex: 'prodType',
    scopedSlots: { customRender: 'prodType' }
  },
  {
    title: '产品名称',
    dataIndex: 'prodName',
    scopedSlots: { customRender: 'prodName' }
  },
  {
    title: '保险公司',
    dataIndex: 'company',
    scopedSlots: { customRender: 'company' }
  },
  {
    title: '金额',
    dataIndex: 'prodAmount',
    scopedSlots: { customRender: 'prodAmount' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'InsuranceCartTable',
  data () {
    return {
      columns
    }
  },
  props: {
    list: Array
  },
  methods: {
    onDelete (record) {
      this.$emit('delete', record)
    }
  },
  filters: {
    serviceTypeFilter
  }
}
</script>

<style lang="scss" scoped>
</style>
